import {
  Business, BusinessProgram, Cardholder, MerchantRuleWithRestrictions, User,
} from '@/api/models';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const Business = {};

const store = createStore({
  state: {
    baseBusiness: {} as Business,
    business: {} as Business,
    cardholder: {} as Cardholder,
    programs: [] as BusinessProgram[],
    user: {} as User,
    currentMerchantRule: {} as MerchantRuleWithRestrictions,
    businessMerchantRules: [] as MerchantRuleWithRestrictions[],
  },
  mutations: {
    SET_BASE_BUSINESS(state, baseBusiness) {
      state.baseBusiness = baseBusiness;
    },
    SET_BUSINESS(state, business) {
      state.business = business;
    },
    SET_CARDHOLDER(state, cardholder) {
      state.cardholder = cardholder;
    },
    SET_PROGRAMS(state, programs) {
      state.programs = programs;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_CURRENT_MERCHANT_RULE(state, currentMerchantRule) {
      state.currentMerchantRule = currentMerchantRule;
    },
    SET_BUSINESS_MERCHANT_RULES(state, businessMerchantRules) {
      state.businessMerchantRules = businessMerchantRules;
    },
  },
  actions: {
    updateBaseBusiness({ commit }, baseBusiness) {
      commit('SET_BASE_BUSINESS', baseBusiness);
    },
    updateBusiness({ commit }, business) {
      commit('SET_BUSINESS', business);
    },
    updateCardholder({ commit }, cardholder) {
      commit('SET_CARDHOLDER', cardholder);
    },
    updatePrograms({ commit }, programs) {
      commit('SET_PROGRAMS', programs);
    },
    updateUser({ commit }, user) {
      commit('SET_USER', user);
    },
    updateCurrentMerchantRule({ commit }, currentMerchantRule) {
      commit('SET_CURRENT_MERCHANT_RULE', currentMerchantRule);
    },
    updateBusinessMerchantRules({ commit }, businessMerchantRules) {
      commit('SET_BUSINESS_MERCHANT_RULES', businessMerchantRules);
    },
    resetState({ commit }) {
      commit('SET_BASE_BUSINESS', {} as Business);
      commit('SET_BUSINESS', {} as Business);
      commit('SET_CARDHOLDER', {} as Cardholder);
      commit('SET_PROGRAMS', [] as BusinessProgram[]);
      commit('SET_USER', {} as User);
      commit('SET_CURRENT_MERCHANT_RULE', {} as MerchantRuleWithRestrictions);
      commit('BUSINESS_MERCHANT_RULES', {} as MerchantRuleWithRestrictions[]);
    },
  },
  plugins: [createPersistedState(({ storage: window.sessionStorage }))],
});

export default store;
