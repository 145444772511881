import { reactive } from 'vue';
import axios from 'axios';
import * as bulmaToast from 'bulma-toast';
import { Auth, getAuth, signOut } from 'firebase/auth';
import { BusinessProgram } from '@/api/models';
import { RouteLocationNormalized } from 'vue-router';
import store from '../store/store';
import hasPermission from './permissions';
import methods from './methods';
import useIsPaybiltDomain from '../composables/useIsPaybiltDomain';

const { isPaybiltDomain } = useIsPaybiltDomain();
const hostName = isPaybiltDomain?.value ? 'Paybilt' : 'GiveCard';

const globalProperties = reactive({
  auth: {
    signOutUser(auth: Auth) {
      signOut(auth);
      store.dispatch('resetState');
    },

    handleSignOutAndPushToLogin(): void {
      const user = getAuth();
      signOut(user);
      window.location.href = '/login'; // can't user router.push because of circular dependencies
      store.dispatch('resetState');
    },

    handleSignOutAndPushToCardholderLanding(): void {
      const user = getAuth();
      signOut(user);
      window.location.href = '/cardholders'; // can't user router.push because of circular dependencies
      store.dispatch('resetState');
    },

    handleSignOutAndPushToPaybiltLanding(): void {
      const user = getAuth();
      signOut(user);
      window.location.href = '/paybilt'; // can't user router.push because of circular dependencies
      store.dispatch('resetState');
    },

    friendly401Message(): void {
      this.handleSignOutAndPushToLogin();
      bulmaToast.toast({
        message: `Authentication Error. Please try logging in again. If this problem persists, please contact ${hostName} support.`,
        type: 'is-danger',
        position: 'top-center',
        pauseOnHover: true,
        duration: 10000,
      });
    },

    friendly404AdminMessage(): void {
      this.handleSignOutAndPushToLogin();
      bulmaToast.toast({
        message: `Authentication Error. Please try logging in again. If this problem persists, please contact ${hostName} support.`,
        type: 'is-danger',
        position: 'top-center',
        pauseOnHover: true,
        duration: 10000,
      });
    },

    friendly404CardholderMessage(): void {
      this.handleSignOutAndPushToCardholderLanding();
      bulmaToast.toast({
        message: `Authentication Error. Please try logging in again. If this problem persists, please contact ${hostName} support.`,
        type: 'is-danger',
        position: 'top-center',
        pauseOnHover: true,
        duration: 10000,
      });
    },

  },
  store: {
    refetchBusinessMerchantRules(): void {
      getAuth().currentUser?.getIdToken().then((token) => {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const url = `${process.env.VUE_APP_WHIMSY_BASE_URL}/businesses/${store.state.business.id}/merchantRules`;
        axios
          .get(url, { headers })
          .then((response) => {
            if (response !== undefined) {
              store.dispatch('updateBusinessMerchantRules', response.data.businessMerchantRules);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              globalProperties.auth.friendly401Message();
            }
          });
      });
    },
    refetchCardholder(to: RouteLocationNormalized, userId: string): void {
      const user = getAuth().currentUser;
      user?.getIdToken().then(() => {
        const url = `${process.env.VUE_APP_WHIMSY_BASE_URL}/cardholders/${userId}/`;
        axios
          .get(url)
          .then((response) => {
            store.dispatch('updateCardholder', response.data.cardholder);
          })
          .catch((error) => {
            if (error.response
              && (error.response.status === 401 || error.response.status === 404)) {
              if (to.fullPath.includes('paybilt')) {
                globalProperties.auth.handleSignOutAndPushToPaybiltLanding();
              }
              globalProperties.auth.handleSignOutAndPushToCardholderLanding();
            }
          });
      });
    },

    refetchUserandBusiness(userId: string): void {
      const user = getAuth().currentUser;
      user?.getIdToken().then((token) => {
        const url = `${process.env.VUE_APP_WHIMSY_BASE_URL}/users/${userId}/`;
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        axios
          .get(url, { headers })
          .then((response) => {
            if (store.state.business.id === undefined || '') {
              store.dispatch('updateBusiness', response.data.business);
            }
            store.dispatch('updateUser', response.data.user);
            store.dispatch('updateBaseBusiness', response.data.business);

            if (response.data.business.scope === 'super') {
              axios
                .get(`${process.env.VUE_APP_WHIMSY_BASE_URL}/businesses/${response.data.business.id}/programs`, { headers })
                .then((programResponse) => {
                  let programs: BusinessProgram[] = [];
                  programs = programResponse.data.programs;
                  store.dispatch('updatePrograms', programs);
                })
                .catch((error) => {
                  if (error.response && error.response.status === 401) {
                    globalProperties.auth.friendly401Message();
                  } else if (error.response
                    && (error.response.status === 404 || error.response.status === 500)) {
                    globalProperties.auth.handleSignOutAndPushToLogin();
                  }
                });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              globalProperties.auth.friendly401Message();
            } else if (error.response && error.response.status === 404) {
              globalProperties.auth.handleSignOutAndPushToLogin();
            }
          });
        sessionStorage.removeItem('redirectPath');
      });
    },
    resetBusinessToBase(): void {
      store.dispatch('updateBusiness', store.state.baseBusiness);
    },
  },

  superBusiness: {

    getHomePath(): string {
      if (store.state.baseBusiness.scope === 'super') {
        return 'programs';
      }
      return 'cards';
    },

    getReportsPath(): string {
      if (store.state.baseBusiness.scope === 'super') {
        return 'superBusinessReports';
      }
      return 'reports';
    },
    getCurrentBusinessName(): string {
      if (store.state.business.scope === 'program') {
        return (store.state.business.programName || store.state.business.legalBusinessName || '');
      }

      return store.state.business.legalBusinessName || '';
    },

  },

  sidebarProps: {
    isOpened: true,
    changeState() {
      this.isOpened = !this.isOpened;
    },
  },
  methods,
  hasPermission,
});
export default globalProperties;
