/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type CreateMerchantRuleParamsMerchantCategoriesItem = typeof CreateMerchantRuleParamsMerchantCategoriesItem[keyof typeof CreateMerchantRuleParamsMerchantCategoriesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateMerchantRuleParamsMerchantCategoriesItem = {
  VETERINARY_SERVICES: 'VETERINARY_SERVICES',
  WINE_PRODUCERS: 'WINE_PRODUCERS',
  CHAMPAGNE_PRODUCERS: 'CHAMPAGNE_PRODUCERS',
  AGRICULTURAL_COOPERATIVE: 'AGRICULTURAL_COOPERATIVE',
  LANDSCAPING_SERVICES: 'LANDSCAPING_SERVICES',
  WHOLESALE: 'WHOLESALE',
  CONCESSION: 'CONCESSION',
  GENERAL_SERVICES: 'GENERAL_SERVICES',
  HEATING_PLUMBING_A_C: 'HEATING_PLUMBING_A_C',
  ELECTRICAL_SERVICES: 'ELECTRICAL_SERVICES',
  MASONRY_STONEWORK_AND_PLASTER: 'MASONRY_STONEWORK_AND_PLASTER',
  CARPENTRY_SERVICES: 'CARPENTRY_SERVICES',
  ROOFING_SIDING_SHEET_METAL: 'ROOFING_SIDING_SHEET_METAL',
  CONCRETE_WORK_SERVICES: 'CONCRETE_WORK_SERVICES',
  SPECIAL_TRADE_SERVICES: 'SPECIAL_TRADE_SERVICES',
  FAST_FOOD: 'FAST_FOOD',
  MISCELLANEOUS_PUBLISHING_AND_PRINTING: 'MISCELLANEOUS_PUBLISHING_AND_PRINTING',
  TYPESETTING_PLATE_MAKING_AND_RELATED_SERVICES: 'TYPESETTING_PLATE_MAKING_AND_RELATED_SERVICES',
  SPECIALTY_CLEANING: 'SPECIALTY_CLEANING',
  KYORITSUHOTELS: 'KYORITSUHOTELS',
  RAILROADS: 'RAILROADS',
  COMMUTER_TRANSPORT_AND_FERRIES: 'COMMUTER_TRANSPORT_AND_FERRIES',
  PASSENGER_RAILWAYS: 'PASSENGER_RAILWAYS',
  AMBULANCE_SERVICES: 'AMBULANCE_SERVICES',
  TAXICABS_LIMOUSINES: 'TAXICABS_LIMOUSINES',
  BUS_LINES: 'BUS_LINES',
  MOTOR_FREIGHT_CARRIERS_AND_TRUCKING: 'MOTOR_FREIGHT_CARRIERS_AND_TRUCKING',
  COURIER_SERVICES: 'COURIER_SERVICES',
  PUBLIC_WAREHOUSING_AND_STORAGE: 'PUBLIC_WAREHOUSING_AND_STORAGE',
  CRUISE_LINES: 'CRUISE_LINES',
  BOAT_RENTALS_AND_LEASES: 'BOAT_RENTALS_AND_LEASES',
  MARINAS_SERVICE_AND_SUPPLIES: 'MARINAS_SERVICE_AND_SUPPLIES',
  AIRLINES_AIR_CARRIERS: 'AIRLINES_AIR_CARRIERS',
  AIRPORTS_FLYING_FIELDS: 'AIRPORTS_FLYING_FIELDS',
  TRAVEL_AGENCIES_TOUR_OPERATORS: 'TRAVEL_AGENCIES_TOUR_OPERATORS',
  T_UI_TRAVEL_GERMANY: 'T_UI_TRAVEL_GERMANY',
  TOLLS_BRIDGE_FEES: 'TOLLS_BRIDGE_FEES',
  TRANSPORTATION_SERVICES: 'TRANSPORTATION_SERVICES',
  TELECOMMUNICATION_EQUIPMENT_AND_TELEPHONE_SALES: 'TELECOMMUNICATION_EQUIPMENT_AND_TELEPHONE_SALES',
  TELECOMMUNICATION_SERVICES: 'TELECOMMUNICATION_SERVICES',
  COMPUTER_NETWORK_SERVICES: 'COMPUTER_NETWORK_SERVICES',
  TELEGRAPH_SERVICES: 'TELEGRAPH_SERVICES',
  WIRES_MONEY_ORDERS: 'WIRES_MONEY_ORDERS',
  CABLE_SATELLITE_AND_OTHER_PAY_TELEVISION_AND_RADIO: 'CABLE_SATELLITE_AND_OTHER_PAY_TELEVISION_AND_RADIO',
  UTILITIES: 'UTILITIES',
  MOTOR_VEHICLE_SUPPLIES_AND_NEW_PARTS: 'MOTOR_VEHICLE_SUPPLIES_AND_NEW_PARTS',
  OFFICE_AND_COMMERCIAL_FURNITURE: 'OFFICE_AND_COMMERCIAL_FURNITURE',
  CONSTRUCTION_MATERIALS: 'CONSTRUCTION_MATERIALS',
  PHOTOGRAPHIC_PHOTOCOPY_MICROFILM_EQUIPMENT_AND_SUPPLIES: 'PHOTOGRAPHIC_PHOTOCOPY_MICROFILM_EQUIPMENT_AND_SUPPLIES',
  COMPUTERS_PERIPHERALS_AND_SOFTWARE: 'COMPUTERS_PERIPHERALS_AND_SOFTWARE',
  COMMERCIAL_EQUIPMENT: 'COMMERCIAL_EQUIPMENT',
  MEDICAL_DENTAL_OPHTHALMIC_AND_HOSPITAL_EQUIPMENT_AND_SUPPLIES: 'MEDICAL_DENTAL_OPHTHALMIC_AND_HOSPITAL_EQUIPMENT_AND_SUPPLIES',
  METAL_SERVICE_CENTERS: 'METAL_SERVICE_CENTERS',
  ELECTRICAL_PARTS_AND_EQUIPMENT: 'ELECTRICAL_PARTS_AND_EQUIPMENT',
  HARDWARE_EQUIPMENT_AND_SUPPLIES: 'HARDWARE_EQUIPMENT_AND_SUPPLIES',
  PLUMBING_HEATING_EQUIPMENT_AND_SUPPLIES: 'PLUMBING_HEATING_EQUIPMENT_AND_SUPPLIES',
  INDUSTRIAL_SUPPLIES: 'INDUSTRIAL_SUPPLIES',
  PRECIOUS_STONES_AND_METALS_WATCHES_AND_JEWELRY: 'PRECIOUS_STONES_AND_METALS_WATCHES_AND_JEWELRY',
  DURABLE_GOODS: 'DURABLE_GOODS',
  STATIONARY_OFFICE_SUPPLIES_PRINTING_AND_WRITING_PAPER: 'STATIONARY_OFFICE_SUPPLIES_PRINTING_AND_WRITING_PAPER',
  DRUGS_DRUG_PROPRIETARIES_AND_DRUGGIST_SUNDRIES: 'DRUGS_DRUG_PROPRIETARIES_AND_DRUGGIST_SUNDRIES',
  PIECE_GOODS_NOTIONS_AND_OTHER_DRY_GOODS: 'PIECE_GOODS_NOTIONS_AND_OTHER_DRY_GOODS',
  UNIFORMS_COMMERCIAL_CLOTHING: 'UNIFORMS_COMMERCIAL_CLOTHING',
  COMMERCIAL_FOOTWEAR: 'COMMERCIAL_FOOTWEAR',
  CHEMICALS_AND_ALLIED_PRODUCTS: 'CHEMICALS_AND_ALLIED_PRODUCTS',
  PETROLEUM_AND_PETROLEUM_PRODUCTS: 'PETROLEUM_AND_PETROLEUM_PRODUCTS',
  BOOKS_PERIODICALS_AND_NEWSPAPERS: 'BOOKS_PERIODICALS_AND_NEWSPAPERS',
  FLORISTS_SUPPLIES_NURSERY_STOCK_AND_FLOWERS: 'FLORISTS_SUPPLIES_NURSERY_STOCK_AND_FLOWERS',
  PAINTS_VARNISHES_AND_SUPPLIES: 'PAINTS_VARNISHES_AND_SUPPLIES',
  NONDURABLE_GOODS: 'NONDURABLE_GOODS',
  HOME_SUPPLY_WAREHOUSE_STORES: 'HOME_SUPPLY_WAREHOUSE_STORES',
  LUMBER_BUILDING_MATERIALS_STORES: 'LUMBER_BUILDING_MATERIALS_STORES',
  GLASS_PAINT_AND_WALLPAPER_STORES: 'GLASS_PAINT_AND_WALLPAPER_STORES',
  HARDWARE_STORES: 'HARDWARE_STORES',
  NURSERIES_LAWN_AND_GARDEN_SUPPLY_STORES: 'NURSERIES_LAWN_AND_GARDEN_SUPPLY_STORES',
  ONLINE_MARKETPLACES: 'ONLINE_MARKETPLACES',
  MOBILE_HOME_DEALERS: 'MOBILE_HOME_DEALERS',
  WHOLESALE_CLUBS: 'WHOLESALE_CLUBS',
  DUTY_FREE_STORES: 'DUTY_FREE_STORES',
  DISCOUNT_STORES: 'DISCOUNT_STORES',
  DEPARTMENT_STORES: 'DEPARTMENT_STORES',
  VARIETY_STORES: 'VARIETY_STORES',
  MISCELLANEOUS_GENERAL_MERCHANDISE: 'MISCELLANEOUS_GENERAL_MERCHANDISE',
  GROCERY_STORES_SUPERMARKETS: 'GROCERY_STORES_SUPERMARKETS',
  FREEZER_AND_LOCKER_MEAT_PROVISIONERS: 'FREEZER_AND_LOCKER_MEAT_PROVISIONERS',
  CANDY_NUT_AND_CONFECTIONERY_STORES: 'CANDY_NUT_AND_CONFECTIONERY_STORES',
  DAIRY_PRODUCTS_STORES: 'DAIRY_PRODUCTS_STORES',
  BAKERIES: 'BAKERIES',
  MISCELLANEOUS_FOOD_STORES: 'MISCELLANEOUS_FOOD_STORES',
  CAR_AND_TRUCK_DEALERS_NEW_USED: 'CAR_AND_TRUCK_DEALERS_NEW_USED',
  CAR_AND_TRUCK_DEALERS_USED_ONLY: 'CAR_AND_TRUCK_DEALERS_USED_ONLY',
  AUTO_AND_HOME_SUPPLY_STORES: 'AUTO_AND_HOME_SUPPLY_STORES',
  AUTOMOTIVE_TIRE_STORES: 'AUTOMOTIVE_TIRE_STORES',
  AUTOMOTIVE_PARTS_AND_ACCESSORIES_STORES: 'AUTOMOTIVE_PARTS_AND_ACCESSORIES_STORES',
  SERVICE_STATIONS: 'SERVICE_STATIONS',
  AUTOMATED_FUEL_DISPENSERS: 'AUTOMATED_FUEL_DISPENSERS',
  BOAT_DEALERS: 'BOAT_DEALERS',
  ELECTRIC_VEHICLE_CHARGING: 'ELECTRIC_VEHICLE_CHARGING',
  MOTORCYCLE_SHOPS_DEALERS: 'MOTORCYCLE_SHOPS_DEALERS',
  MOTORCYCLE_SHOPS_AND_DEALERS: 'MOTORCYCLE_SHOPS_AND_DEALERS',
  MOTOR_HOMES_DEALERS: 'MOTOR_HOMES_DEALERS',
  SNOWMOBILE_DEALERS: 'SNOWMOBILE_DEALERS',
  MISCELLANEOUS_AUTO_DEALERS: 'MISCELLANEOUS_AUTO_DEALERS',
  MENS_AND_BOYS_CLOTHING_AND_ACCESSORIES_STORES: 'MENS_AND_BOYS_CLOTHING_AND_ACCESSORIES_STORES',
  WOMENS_READY_TO_WEAR_STORES: 'WOMENS_READY_TO_WEAR_STORES',
  WOMENS_ACCESSORY_AND_SPECIALTY_SHOPS: 'WOMENS_ACCESSORY_AND_SPECIALTY_SHOPS',
  CHILDRENS_AND_INFANTS_WEAR_STORES: 'CHILDRENS_AND_INFANTS_WEAR_STORES',
  FAMILY_CLOTHING_STORES: 'FAMILY_CLOTHING_STORES',
  SPORTS_AND_RIDING_APPAREL_STORES: 'SPORTS_AND_RIDING_APPAREL_STORES',
  SHOE_STORES: 'SHOE_STORES',
  FURRIERS_AND_FUR_SHOPS: 'FURRIERS_AND_FUR_SHOPS',
  MENS_WOMENS_CLOTHING_STORES: 'MENS_WOMENS_CLOTHING_STORES',
  TAILORS_ALTERATIONS: 'TAILORS_ALTERATIONS',
  WIG_AND_TOUPEE_STORES: 'WIG_AND_TOUPEE_STORES',
  MISCELLANEOUS_APPAREL_AND_ACCESSORY_SHOPS: 'MISCELLANEOUS_APPAREL_AND_ACCESSORY_SHOPS',
  FURNITURE_HOME_FURNISHINGS_AND_EQUIPMENT_STORES_EXCEPT_APPLIANCES: 'FURNITURE_HOME_FURNISHINGS_AND_EQUIPMENT_STORES_EXCEPT_APPLIANCES',
  FLOOR_COVERING_STORES: 'FLOOR_COVERING_STORES',
  DRAPERY_WINDOW_COVERING_AND_UPHOLSTERY_STORES: 'DRAPERY_WINDOW_COVERING_AND_UPHOLSTERY_STORES',
  FIREPLACE_FIREPLACE_SCREENS_AND_ACCESSORIES_STORES: 'FIREPLACE_FIREPLACE_SCREENS_AND_ACCESSORIES_STORES',
  MISCELLANEOUS_HOME_FURNISHING_SPECIALTY_STORES: 'MISCELLANEOUS_HOME_FURNISHING_SPECIALTY_STORES',
  HOUSEHOLD_APPLIANCE_STORES: 'HOUSEHOLD_APPLIANCE_STORES',
  ELECTRONICS_STORES: 'ELECTRONICS_STORES',
  MUSIC_STORES_MUSICAL_INSTRUMENTS_PIANOS_AND_SHEET_MUSIC: 'MUSIC_STORES_MUSICAL_INSTRUMENTS_PIANOS_AND_SHEET_MUSIC',
  COMPUTER_SOFTWARE_STORES: 'COMPUTER_SOFTWARE_STORES',
  RECORD_STORES: 'RECORD_STORES',
  CATERERS: 'CATERERS',
  EATING_PLACES_RESTAURANTS: 'EATING_PLACES_RESTAURANTS',
  DRINKING_PLACES: 'DRINKING_PLACES',
  FAST_FOOD_RESTAURANTS: 'FAST_FOOD_RESTAURANTS',
  DIGITAL_GOODS_MEDIA: 'DIGITAL_GOODS_MEDIA',
  DIGITAL_GOODS_GAMES: 'DIGITAL_GOODS_GAMES',
  DIGITAL_GOODS_APPLICATIONS: 'DIGITAL_GOODS_APPLICATIONS',
  DIGITAL_GOODS_LARGE_VOLUME: 'DIGITAL_GOODS_LARGE_VOLUME',
  DRUG_STORES_AND_PHARMACIES: 'DRUG_STORES_AND_PHARMACIES',
  PACKAGE_STORES_BEER_WINE_AND_LIQUOR: 'PACKAGE_STORES_BEER_WINE_AND_LIQUOR',
  USED_MERCHANDISE_AND_SECONDHAND_STORES: 'USED_MERCHANDISE_AND_SECONDHAND_STORES',
  ANTIQUE_SHOPS: 'ANTIQUE_SHOPS',
  PAWN_SHOPS: 'PAWN_SHOPS',
  WRECKING_AND_SALVAGE_YARDS: 'WRECKING_AND_SALVAGE_YARDS',
  ANTIQUE_REPRODUCTIONS: 'ANTIQUE_REPRODUCTIONS',
  BICYCLE_SHOPS: 'BICYCLE_SHOPS',
  SPORTING_GOODS_STORES: 'SPORTING_GOODS_STORES',
  BOOK_STORES: 'BOOK_STORES',
  STATIONERY_STORES_OFFICE_AND_SCHOOL_SUPPLY_STORES: 'STATIONERY_STORES_OFFICE_AND_SCHOOL_SUPPLY_STORES',
  JEWELRY_STORES_WATCHES_CLOCKS_AND_SILVERWARE_STORES: 'JEWELRY_STORES_WATCHES_CLOCKS_AND_SILVERWARE_STORES',
  HOBBY_TOY_AND_GAME_SHOPS: 'HOBBY_TOY_AND_GAME_SHOPS',
  CAMERA_AND_PHOTOGRAPHIC_SUPPLY_STORES: 'CAMERA_AND_PHOTOGRAPHIC_SUPPLY_STORES',
  GIFT_CARD_NOVELTY_AND_SOUVENIR_SHOPS: 'GIFT_CARD_NOVELTY_AND_SOUVENIR_SHOPS',
  LUGGAGE_AND_LEATHER_GOODS_STORES: 'LUGGAGE_AND_LEATHER_GOODS_STORES',
  SEWING_NEEDLEWORK_FABRIC_AND_PIECE_GOODS_STORES: 'SEWING_NEEDLEWORK_FABRIC_AND_PIECE_GOODS_STORES',
  GLASSWARE_CRYSTAL_STORES: 'GLASSWARE_CRYSTAL_STORES',
  DIRECT_MARKETING_INSURANCE_SERVICES: 'DIRECT_MARKETING_INSURANCE_SERVICES',
  DIRECT_MARKETING_TRAVEL: 'DIRECT_MARKETING_TRAVEL',
  DOOR_TO_DOOR_SALES: 'DOOR_TO_DOOR_SALES',
  DIRECT_MARKETING_CATALOG_MERCHANT: 'DIRECT_MARKETING_CATALOG_MERCHANT',
  DIRECT_MARKETING_COMBINATION_CATALOG_AND_RETAIL_MERCHANT: 'DIRECT_MARKETING_COMBINATION_CATALOG_AND_RETAIL_MERCHANT',
  DIRECT_MARKETING_OUTBOUND_TELEMARKETING: 'DIRECT_MARKETING_OUTBOUND_TELEMARKETING',
  DIRECT_MARKETING_INBOUND_TELEMARKETING: 'DIRECT_MARKETING_INBOUND_TELEMARKETING',
  DIRECT_MARKETING_SUBSCRIPTION: 'DIRECT_MARKETING_SUBSCRIPTION',
  DIRECT_MARKETING_OTHER: 'DIRECT_MARKETING_OTHER',
  ARTISTS_SUPPLY_AND_CRAFT_SHOPS: 'ARTISTS_SUPPLY_AND_CRAFT_SHOPS',
  ART_DEALERS_AND_GALLERIES: 'ART_DEALERS_AND_GALLERIES',
  STAMP_AND_COIN_STORES: 'STAMP_AND_COIN_STORES',
  RELIGIOUS_GOODS_STORES: 'RELIGIOUS_GOODS_STORES',
  HEARING_AIDS_SALES_AND_SUPPLIES: 'HEARING_AIDS_SALES_AND_SUPPLIES',
  ORTHOPEDIC_GOODS_PROSTHETIC_DEVICES: 'ORTHOPEDIC_GOODS_PROSTHETIC_DEVICES',
  COSMETIC_STORES: 'COSMETIC_STORES',
  TYPEWRITER_STORES: 'TYPEWRITER_STORES',
  FUEL_DEALERS_NON_AUTOMOTIVE: 'FUEL_DEALERS_NON_AUTOMOTIVE',
  FLORISTS: 'FLORISTS',
  CIGAR_STORES_AND_STANDS: 'CIGAR_STORES_AND_STANDS',
  NEWS_DEALERS_AND_NEWSSTANDS: 'NEWS_DEALERS_AND_NEWSSTANDS',
  PET_SHOPS_PET_FOOD_AND_SUPPLIES: 'PET_SHOPS_PET_FOOD_AND_SUPPLIES',
  SWIMMING_POOLS_SALES: 'SWIMMING_POOLS_SALES',
  ELECTRIC_RAZOR_STORES: 'ELECTRIC_RAZOR_STORES',
  TENT_AND_AWNING_SHOPS: 'TENT_AND_AWNING_SHOPS',
  MISCELLANEOUS_SPECIALTY_RETAIL: 'MISCELLANEOUS_SPECIALTY_RETAIL',
  MANUAL_CASH_DISBURSE: 'MANUAL_CASH_DISBURSE',
  AUTOMATED_CASH_DISBURSE: 'AUTOMATED_CASH_DISBURSE',
  FINANCIAL_INSTITUTIONS: 'FINANCIAL_INSTITUTIONS',
  QUASI_CASH_CUSTOMER_FINANCIAL_INSTITUTIONS: 'QUASI_CASH_CUSTOMER_FINANCIAL_INSTITUTIONS',
  NON_FI_MONEY_ORDERS: 'NON_FI_MONEY_ORDERS',
  INSURANCE_UNDERWRITING_PREMIUMS: 'INSURANCE_UNDERWRITING_PREMIUMS',
  INSURANCE_DEFAULT: 'INSURANCE_DEFAULT',
  REAL_ESTATE_AGENTS_AND_MANAGERS_RENTALS: 'REAL_ESTATE_AGENTS_AND_MANAGERS_RENTALS',
  NON_FI_STORED_VALUE_CARD_PURCHASE_LOAD: 'NON_FI_STORED_VALUE_CARD_PURCHASE_LOAD',
  HOTELS_MOTELS_AND_RESORTS: 'HOTELS_MOTELS_AND_RESORTS',
  TIMESHARES: 'TIMESHARES',
  SPORTING_RECREATION_CAMPS: 'SPORTING_RECREATION_CAMPS',
  TRAILER_PARKS_CAMPGROUNDS: 'TRAILER_PARKS_CAMPGROUNDS',
  LAUNDRY_CLEANING_SERVICES: 'LAUNDRY_CLEANING_SERVICES',
  LAUNDRIES: 'LAUNDRIES',
  DRY_CLEANERS: 'DRY_CLEANERS',
  CARPET_UPHOLSTERY_CLEANING: 'CARPET_UPHOLSTERY_CLEANING',
  PHOTOGRAPHIC_STUDIOS: 'PHOTOGRAPHIC_STUDIOS',
  BARBER_AND_BEAUTY_SHOPS: 'BARBER_AND_BEAUTY_SHOPS',
  SHOE_REPAIR_HAT_CLEANING: 'SHOE_REPAIR_HAT_CLEANING',
  FUNERAL_SERVICES_CREMATORIES: 'FUNERAL_SERVICES_CREMATORIES',
  TAX_PREPARATION_SERVICES: 'TAX_PREPARATION_SERVICES',
  COUNSELING_SERVICES: 'COUNSELING_SERVICES',
  BUYING_SHOPPING_SERVICES: 'BUYING_SHOPPING_SERVICES',
  CLOTHING_RENTAL: 'CLOTHING_RENTAL',
  MASSAGE_PARLORS: 'MASSAGE_PARLORS',
  HEALTH_AND_BEAUTY_SPAS: 'HEALTH_AND_BEAUTY_SPAS',
  MISCELLANEOUS_GENERAL_SERVICES: 'MISCELLANEOUS_GENERAL_SERVICES',
  ADVERTISING_SERVICES: 'ADVERTISING_SERVICES',
  CREDIT_REPORTING_AGENCIES: 'CREDIT_REPORTING_AGENCIES',
  COMMERCIAL_PHOTOGRAPHY_ART_AND_GRAPHICS: 'COMMERCIAL_PHOTOGRAPHY_ART_AND_GRAPHICS',
  QUICK_COPY_REPRO_AND_BLUEPRINT: 'QUICK_COPY_REPRO_AND_BLUEPRINT',
  SECRETARIAL_SUPPORT_SERVICES: 'SECRETARIAL_SUPPORT_SERVICES',
  EXTERMINATING_SERVICES: 'EXTERMINATING_SERVICES',
  CLEANING_AND_MAINTENANCE: 'CLEANING_AND_MAINTENANCE',
  EMPLOYMENT_TEMP_AGENCIES: 'EMPLOYMENT_TEMP_AGENCIES',
  COMPUTER_PROGRAMMING: 'COMPUTER_PROGRAMMING',
  INFORMATION_RETRIEVAL_SERVICES: 'INFORMATION_RETRIEVAL_SERVICES',
  COMPUTER_REPAIR: 'COMPUTER_REPAIR',
  CONSULTING_PUBLIC_RELATIONS: 'CONSULTING_PUBLIC_RELATIONS',
  DETECTIVE_AGENCIES: 'DETECTIVE_AGENCIES',
  EQUIPMENT_RENTAL: 'EQUIPMENT_RENTAL',
  PHOTO_DEVELOPING: 'PHOTO_DEVELOPING',
  MISCELLANEOUS_BUSINESS_SERVICES: 'MISCELLANEOUS_BUSINESS_SERVICES',
  TRUCK_STOP_ITERATION: 'TRUCK_STOP_ITERATION',
  CAR_RENTAL_AGENCIES: 'CAR_RENTAL_AGENCIES',
  CAR_RENTAL: 'CAR_RENTAL',
  TRUCK_UTILITY_TRAILER_RENTALS: 'TRUCK_UTILITY_TRAILER_RENTALS',
  RECREATIONAL_VEHICLE_RENTALS: 'RECREATIONAL_VEHICLE_RENTALS',
  PARKING_LOTS_GARAGES: 'PARKING_LOTS_GARAGES',
  AUTO_BODY_REPAIR_SHOPS: 'AUTO_BODY_REPAIR_SHOPS',
  TIRE_RETREADING_AND_REPAIR: 'TIRE_RETREADING_AND_REPAIR',
  AUTO_PAINT_SHOPS: 'AUTO_PAINT_SHOPS',
  AUTO_SERVICE_SHOPS: 'AUTO_SERVICE_SHOPS',
  CAR_WASHES: 'CAR_WASHES',
  TOWING_SERVICES: 'TOWING_SERVICES',
  ELECTRONICS_REPAIR_SHOPS: 'ELECTRONICS_REPAIR_SHOPS',
  AC_REFRIGERATION_REPAIR: 'AC_REFRIGERATION_REPAIR',
  SMALL_APPLIANCE_REPAIR: 'SMALL_APPLIANCE_REPAIR',
  WATCH_JEWELRY_REPAIR: 'WATCH_JEWELRY_REPAIR',
  FURNITURE_REPAIR_REFINISHING: 'FURNITURE_REPAIR_REFINISHING',
  WELDING_REPAIR: 'WELDING_REPAIR',
  MISCELLANEOUS_REPAIR_SHOPS: 'MISCELLANEOUS_REPAIR_SHOPS',
  PICTURE_VIDEO_PRODUCTION: 'PICTURE_VIDEO_PRODUCTION',
  MOTION_PICTURE_THEATERS: 'MOTION_PICTURE_THEATERS',
  VIDEO_TAPE_RENTAL_STORES: 'VIDEO_TAPE_RENTAL_STORES',
  DANCE_HALL_STUDIOS_SCHOOLS: 'DANCE_HALL_STUDIOS_SCHOOLS',
  THEATRICAL_TICKET_AGENCIES: 'THEATRICAL_TICKET_AGENCIES',
  BANDS_ORCHESTRAS: 'BANDS_ORCHESTRAS',
  BILLIARD_POOL_ESTABLISHMENTS: 'BILLIARD_POOL_ESTABLISHMENTS',
  BOWLING_ALLEYS: 'BOWLING_ALLEYS',
  SPORTS_CLUBS_FIELDS: 'SPORTS_CLUBS_FIELDS',
  TOURIST_ATTRACTIONS_AND_EXHIBITS: 'TOURIST_ATTRACTIONS_AND_EXHIBITS',
  GOLF_COURSES_PUBLIC: 'GOLF_COURSES_PUBLIC',
  VIDEO_AMUSEMENT_GAME_SUPPLIES: 'VIDEO_AMUSEMENT_GAME_SUPPLIES',
  VIDEO_GAME_ARCADES: 'VIDEO_GAME_ARCADES',
  AMUSEMENT_PARKS_CARNIVALS: 'AMUSEMENT_PARKS_CARNIVALS',
  COUNTRY_CLUBS: 'COUNTRY_CLUBS',
  AQUARIUMS: 'AQUARIUMS',
  MISCELLANEOUS_RECREATION_SERVICES: 'MISCELLANEOUS_RECREATION_SERVICES',
  DOCTORS: 'DOCTORS',
  DENTISTS_ORTHODONTISTS: 'DENTISTS_ORTHODONTISTS',
  OSTEOPATHS: 'OSTEOPATHS',
  CHIROPRACTORS: 'CHIROPRACTORS',
  OPTOMETRISTS_OPHTHALMOLOGIST: 'OPTOMETRISTS_OPHTHALMOLOGIST',
  OPTICIANS_EYEGLASSES: 'OPTICIANS_EYEGLASSES',
  CHIROPODISTS_PODIATRISTS: 'CHIROPODISTS_PODIATRISTS',
  NURSING_PERSONAL_CARE: 'NURSING_PERSONAL_CARE',
  HOSPITALS: 'HOSPITALS',
  MEDICAL_AND_DENTAL_LABS: 'MEDICAL_AND_DENTAL_LABS',
  MEDICAL_SERVICES: 'MEDICAL_SERVICES',
  LEGAL_SERVICES_ATTORNEYS: 'LEGAL_SERVICES_ATTORNEYS',
  ELEMENTARY_SECONDARY_SCHOOLS: 'ELEMENTARY_SECONDARY_SCHOOLS',
  COLLEGES_UNIVERSITIES: 'COLLEGES_UNIVERSITIES',
  CORRESPONDENCE_SCHOOLS: 'CORRESPONDENCE_SCHOOLS',
  BUSINESS_SECRETARIAL_SCHOOLS: 'BUSINESS_SECRETARIAL_SCHOOLS',
  VOCATIONAL_TRADE_SCHOOLS: 'VOCATIONAL_TRADE_SCHOOLS',
  EDUCATIONAL_SERVICES: 'EDUCATIONAL_SERVICES',
  CHILD_CARE_SERVICES: 'CHILD_CARE_SERVICES',
  CHARITABLE_AND_SOCIAL_SERVICE_ORGANIZATIONS_FUNDRAISING: 'CHARITABLE_AND_SOCIAL_SERVICE_ORGANIZATIONS_FUNDRAISING',
  CIVIC_SOCIAL_FRATERNAL_ASSOCIATIONS: 'CIVIC_SOCIAL_FRATERNAL_ASSOCIATIONS',
  POLITICAL_ORGANIZATIONS: 'POLITICAL_ORGANIZATIONS',
  RELIGIOUS_ORGANIZATIONS: 'RELIGIOUS_ORGANIZATIONS',
  AUTOMOBILE_ASSOCIATIONS: 'AUTOMOBILE_ASSOCIATIONS',
  MEMBERSHIP_ORGANIZATIONS: 'MEMBERSHIP_ORGANIZATIONS',
  TESTING_LABORATORIES: 'TESTING_LABORATORIES',
  ARCHITECTURAL_SURVEYING_SERVICES: 'ARCHITECTURAL_SURVEYING_SERVICES',
  ACCOUNTING_BOOKKEEPING_SERVICES: 'ACCOUNTING_BOOKKEEPING_SERVICES',
  PROFESSIONAL_SERVICES: 'PROFESSIONAL_SERVICES',
  COURT_COSTS: 'COURT_COSTS',
  FINES_GOVERNMENT_ADMINISTRATIVE_ENTITIES: 'FINES_GOVERNMENT_ADMINISTRATIVE_ENTITIES',
  BAIL_AND_BOND_PAYMENTS: 'BAIL_AND_BOND_PAYMENTS',
  TAX_PAYMENTS_GOVERNMENT_AGENCIES: 'TAX_PAYMENTS_GOVERNMENT_AGENCIES',
  GOVERNMENT_SERVICES: 'GOVERNMENT_SERVICES',
  POSTAL_SERVICES_GOVERNMENT_ONLY: 'POSTAL_SERVICES_GOVERNMENT_ONLY',
  US_FEDERAL_GOVERNMENT_AGENCIES_OR_DEPARTMENTS: 'US_FEDERAL_GOVERNMENT_AGENCIES_OR_DEPARTMENTS',
  GOVERNMENT_OWNED_LOTTERY_GLOBAL: 'GOVERNMENT_OWNED_LOTTERY_GLOBAL',
  INTRA_COMPANY_PURCHASES: 'INTRA_COMPANY_PURCHASES',
  BLUEPRINTING_AND_PHOTOCOPYING_SERVICES: 'BLUEPRINTING_AND_PHOTOCOPYING_SERVICES',
  HOME_2_SUITES: 'HOME_2_SUITES',
  MASTERS_ECONOMY_INNS: 'MASTERS_ECONOMY_INNS',
  UNITED_AIRLINES: 'UNITED_AIRLINES',
  AMERICAN_AIRLINES: 'AMERICAN_AIRLINES',
  PAN_AMERICAN: 'PAN_AMERICAN',
  TRANS_WORLD_AIRLINES: 'TRANS_WORLD_AIRLINES',
  BRITISH_AIRWAYS: 'BRITISH_AIRWAYS',
  JAPAN_AIRLINES: 'JAPAN_AIRLINES',
  AIR_FRANCE: 'AIR_FRANCE',
  LUFTHANSA: 'LUFTHANSA',
  AIR_CANADA: 'AIR_CANADA',
  KLM_ROYAL_DUTCH_AIRLINES: 'KLM_ROYAL_DUTCH_AIRLINES',
  AEORFLOT: 'AEORFLOT',
  QUANTAS: 'QUANTAS',
  ALITALIA: 'ALITALIA',
  SAUDIA_ARABIAN_AIRLINES: 'SAUDIA_ARABIAN_AIRLINES',
  SWISS_AIR: 'SWISS_AIR',
  SCANDINAVIAN_AIRLINE_SYSTEM: 'SCANDINAVIAN_AIRLINE_SYSTEM',
  SOUTH_AFRICAN_AIRWAYS: 'SOUTH_AFRICAN_AIRWAYS',
  VARIG: 'VARIG',
  AIR_INDIA: 'AIR_INDIA',
  AIR_ALGERIE: 'AIR_ALGERIE',
  PHILIPPINE_AIRLINES: 'PHILIPPINE_AIRLINES',
  MEXICANA_AIRLINES: 'MEXICANA_AIRLINES',
  PAKISTAN_INTERNATIONAL: 'PAKISTAN_INTERNATIONAL',
  AIR_NEW_ZEALAND: 'AIR_NEW_ZEALAND',
  UTA_INTERAIR: 'UTA_INTERAIR',
  AIR_MALTA: 'AIR_MALTA',
  SABENA: 'SABENA',
  AEROLINEAS_ARGENTINAS: 'AEROLINEAS_ARGENTINAS',
  OLYMPIC_AIRWAYS: 'OLYMPIC_AIRWAYS',
  EL_AL_AIRLINES: 'EL_AL_AIRLINES',
  ANSETT_AIRLINES: 'ANSETT_AIRLINES',
  ETIHAD_AIR: 'ETIHAD_AIR',
  TAP_AIR_PORTUGAL: 'TAP_AIR_PORTUGAL',
  VASP_AIR: 'VASP_AIR',
  EGYPT_AIR: 'EGYPT_AIR',
  KUWAIT_AIR: 'KUWAIT_AIR',
  AVIANCA: 'AVIANCA',
  GULF_AIR: 'GULF_AIR',
  BALKAN_AIR: 'BALKAN_AIR',
  FINNAIR: 'FINNAIR',
  AER_LINGUS: 'AER_LINGUS',
  AIR_LANKA: 'AIR_LANKA',
  NIGERIA_AIR: 'NIGERIA_AIR',
  CRUZIERO_AIR: 'CRUZIERO_AIR',
  THY_AIRLINE: 'THY_AIRLINE',
  AIR_MAROC: 'AIR_MAROC',
  TUNIS_AIR: 'TUNIS_AIR',
  ICELAND_AIR: 'ICELAND_AIR',
  AUSTRIAN_AIR: 'AUSTRIAN_AIR',
  LAN_AIR: 'LAN_AIR',
  AVIACO_AIR: 'AVIACO_AIR',
  LADECO_AIR: 'LADECO_AIR',
  LAB_AIR: 'LAB_AIR',
  JET_AIRWAYS: 'JET_AIRWAYS',
  VIRGIN_AMERICA: 'VIRGIN_AMERICA',
  DELTA_AIRLINES: 'DELTA_AIRLINES',
  DBA_AIR: 'DBA_AIR',
  NWA_AIR: 'NWA_AIR',
  CONTINENTAL_AIRLINES: 'CONTINENTAL_AIRLINES',
  HLX_AIR: 'HLX_AIR',
  US_AIRWAYS: 'US_AIRWAYS',
  ADRIA_AIR: 'ADRIA_AIR',
  AIRINTER: 'AIRINTER',
  SOUTHWEST_AIRLINES: 'SOUTHWEST_AIRLINES',
  VANGUARD_AIR: 'VANGUARD_AIR',
  AIR_ASTANA: 'AIR_ASTANA',
  SUN_COUNTRY_AIRLINES: 'SUN_COUNTRY_AIRLINES',
  AIR_BRITISH_COLUMBIA: 'AIR_BRITISH_COLUMBIA',
  CEBU_PACIFIC_AIRLINES: 'CEBU_PACIFIC_AIRLINES',
  SINGAPORE_AIRLINES: 'SINGAPORE_AIRLINES',
  AEROMEXICO: 'AEROMEXICO',
  THAI_AIRWAYS: 'THAI_AIRWAYS',
  CHINA_AIRLINES: 'CHINA_AIRLINES',
  JETSTAR_AIRWAYS: 'JETSTAR_AIRWAYS',
  NORD_AIR: 'NORD_AIR',
  KOREAN_AIRLINES: 'KOREAN_AIRLINES',
  AIR_AFRIQUE: 'AIR_AFRIQUE',
  EVA_AIRWAYS: 'EVA_AIRWAYS',
  MIDWEST_EXPRESS_AIRLINES: 'MIDWEST_EXPRESS_AIRLINES',
  CARNIVAL_AIRLINES: 'CARNIVAL_AIRLINES',
  METRO_AIRLINES: 'METRO_AIRLINES',
  CROATIA_AIR: 'CROATIA_AIR',
  TRANSAERO: 'TRANSAERO',
  UNI_AIRWAYS_CORPORATION: 'UNI_AIRWAYS_CORPORATION',
  ZAMBIA_AIRWAYS: 'ZAMBIA_AIRWAYS',
  AIR_ZIMBABWE_CORP: 'AIR_ZIMBABWE_CORP',
  SPANAIR: 'SPANAIR',
  ASIANA_AIRLINES: 'ASIANA_AIRLINES',
  CATHAY_PACIFIC: 'CATHAY_PACIFIC',
  MALAYSIAN_AIRLINE_SYSTEM: 'MALAYSIAN_AIRLINE_SYSTEM',
  IBERIA: 'IBERIA',
  GARUDA: 'GARUDA',
  BRAATHENS: 'BRAATHENS',
  WINGS_AIRWAYS: 'WINGS_AIRWAYS',
  BRITISH_MIDLAND_AIR: 'BRITISH_MIDLAND_AIR',
  WINDWARD_ISLAND_AIR: 'WINDWARD_ISLAND_AIR',
  TOWER_AIR: 'TOWER_AIR',
  VIASA_AIR: 'VIASA_AIR',
  VALLEY_AIRLINES: 'VALLEY_AIRLINES',
  TAN_AIR: 'TAN_AIR',
  TAL_AIR: 'TAL_AIR',
  TACA_INTERNATIONAL_AIR: 'TACA_INTERNATIONAL_AIR',
  SURINAM_AIRWAYS: 'SURINAM_AIRWAYS',
  SUNWORLD_AIR: 'SUNWORLD_AIR',
  VLM_AIRLINES: 'VLM_AIRLINES',
  FRONTIER_AIRLINES: 'FRONTIER_AIRLINES',
  SUNBELT_AIRLINES: 'SUNBELT_AIRLINES',
  SUDAN_AIRWAYS: 'SUDAN_AIRWAYS',
  QATAR_AIRWAYS: 'QATAR_AIRWAYS',
  SINGLETON_AIR: 'SINGLETON_AIR',
  SIMMONS_AIRLINES: 'SIMMONS_AIRLINES',
  SCENIC_AIRLINES: 'SCENIC_AIRLINES',
  VIRGIN_ATLANTIC: 'VIRGIN_ATLANTIC',
  SAN_JUAN_AIR: 'SAN_JUAN_AIR',
  LUXAIR: 'LUXAIR',
  AIR_LITTORAL: 'AIR_LITTORAL',
  AIR_ZAIRE: 'AIR_ZAIRE',
  PRINCEVILLE_AIR: 'PRINCEVILLE_AIR',
  GOFLY: 'GOFLY',
  PBA_AIRLINE: 'PBA_AIRLINE',
  ANA_AIR: 'ANA_AIR',
  NORONTAIR: 'NORONTAIR',
  NY_HELI: 'NY_HELI',
  AERO_CONTINENTE: 'AERO_CONTINENTE',
  MOUNT_COOK: 'MOUNT_COOK',
  CANADIAN_AIR: 'CANADIAN_AIR',
  NATIONAIR: 'NATIONAIR',
  JETBLUE_AIRWAYS: 'JETBLUE_AIRWAYS',
  MIDDLE_EAST_AIR: 'MIDDLE_EAST_AIR',
  METROFLIGHT_AIRLINES: 'METROFLIGHT_AIRLINES',
  AIRTRAN_AIRWAYS: 'AIRTRAN_AIRWAYS',
  MESA_AIR: 'MESA_AIR',
  WESTJET_AIRLINES: 'WESTJET_AIRLINES',
  MALEV: 'MALEV',
  LOT_AIR: 'LOT_AIR',
  OMAN_AIR: 'OMAN_AIR',
  LIAT_AIRLINE: 'LIAT_AIRLINE',
  LAV_AIR: 'LAV_AIR',
  LAP_AIR: 'LAP_AIR',
  LACSA_AIR: 'LACSA_AIR',
  VIRGIN_EXPRESS_AIR: 'VIRGIN_EXPRESS_AIR',
  JUGOSLAV_AIR: 'JUGOSLAV_AIR',
  ISLAND_AIRLINES: 'ISLAND_AIRLINES',
  INDIAN_AIRLINES: 'INDIAN_AIRLINES',
  HAWAIIAN_AIR: 'HAWAIIAN_AIR',
  HAVASU_AIRLINES: 'HAVASU_AIRLINES',
  GUYANA_AIRWAYS: 'GUYANA_AIRWAYS',
  GOLDEN_PACIFIC_AIR: 'GOLDEN_PACIFIC_AIR',
  FREEDOM_AIRLINES: 'FREEDOM_AIRLINES',
  CHINA_EASTERN_AIRLINES: 'CHINA_EASTERN_AIRLINES',
  NORWEGIAN_AIR_SHUTTLE: 'NORWEGIAN_AIR_SHUTTLE',
  DOMINICA_AIR: 'DOMINICA_AIR',
  MALMO_AIR: 'MALMO_AIR',
  DAN_AIR_SERVICES: 'DAN_AIR_SERVICES',
  CUMBERLAND_AIRLINES: 'CUMBERLAND_AIRLINES',
  CSA_AIRLINE: 'CSA_AIRLINE',
  CROWN_AIR: 'CROWN_AIR',
  COPA_AIR: 'COPA_AIR',
  COMP_FAU_AIR: 'COMP_FAU_AIR',
  TAME_AIR: 'TAME_AIR',
  COMMAND_AIR: 'COMMAND_AIR',
  COMAIR: 'COMAIR',
  SKYWAYS_AIR: 'SKYWAYS_AIR',
  CAYMAN_AIRWAYS: 'CAYMAN_AIRWAYS',
  SAETA_AIR: 'SAETA_AIR',
  SAHSA_AIR: 'SAHSA_AIR',
  CAPITOL_AIR: 'CAPITOL_AIR',
  CARIBBEAN_AIRLINES: 'CARIBBEAN_AIRLINES',
  BROCKWAY_AIR: 'BROCKWAY_AIR',
  AIR_ARABIA: 'AIR_ARABIA',
  BEMIDJI_AIR: 'BEMIDJI_AIR',
  BAR_HARBOR_AIRLINES: 'BAR_HARBOR_AIRLINES',
  BAHAMAS_AIR: 'BAHAMAS_AIR',
  AVIATECA_AIR: 'AVIATECA_AIR',
  AVENSA_AIR: 'AVENSA_AIR',
  AUSTRIAN_AIR_SERVICE: 'AUSTRIAN_AIR_SERVICE',
  EASYJET_AIR: 'EASYJET_AIR',
  RYANAIR: 'RYANAIR',
  GOL_AIRLINES: 'GOL_AIRLINES',
  TAM_AIRLINES: 'TAM_AIRLINES',
  ALOHA_AIRLINES: 'ALOHA_AIRLINES',
  ALM_AIRLINE: 'ALM_AIRLINE',
  AMERICA_WEST: 'AMERICA_WEST',
  US_AIR_SHUTTLE: 'US_AIR_SHUTTLE',
  ALASKA_AIR: 'ALASKA_AIR',
  ATA_AIR: 'ATA_AIR',
  SPIRIT_AIRLINES: 'SPIRIT_AIRLINES',
  AIR_CHINA: 'AIR_CHINA',
  RENO_AIR: 'RENO_AIR',
  ASC_AIRLINE: 'ASC_AIRLINE',
  AIR_SEYCHELLES: 'AIR_SEYCHELLES',
  AIR_PANAMA: 'AIR_PANAMA',
  AIR_JAMAICA: 'AIR_JAMAICA',
  AIR_DJIBOUTI: 'AIR_DJIBOUTI',
  AERO_VIRGIN_ISLANDS: 'AERO_VIRGIN_ISLANDS',
  AERO_PERU: 'AERO_PERU',
  AERO_NICARAGUENSIS: 'AERO_NICARAGUENSIS',
  AERO_COACH_AVIATION: 'AERO_COACH_AVIATION',
  CYPRUS_AIRWAYS: 'CYPRUS_AIRWAYS',
  ECUATORIANA: 'ECUATORIANA',
  ETHIOPIAN_AIRLINES: 'ETHIOPIAN_AIRLINES',
  KENYA_AIRWAYS: 'KENYA_AIRWAYS',
  AIR_BERLIN: 'AIR_BERLIN',
  TAROM_AIR: 'TAROM_AIR',
  AIR_MAURITIUS: 'AIR_MAURITIUS',
  WIDEROES: 'WIDEROES',
  AFFILIATED_AUTO_RENTAL: 'AFFILIATED_AUTO_RENTAL',
  AMERICAN_INTERNATIONAL: 'AMERICAN_INTERNATIONAL',
  BROOKS_RENT_A_CAR: 'BROOKS_RENT_A_CAR',
  ACTION_AUTO_RENTAL: 'ACTION_AUTO_RENTAL',
  SIXT_CAR_RENTAL: 'SIXT_CAR_RENTAL',
  HERTZ: 'HERTZ',
  PAYLESS_CAR_RENTAL: 'PAYLESS_CAR_RENTAL',
  SNAPPY_CAR_RENTAL: 'SNAPPY_CAR_RENTAL',
  AIRWAYS: 'AIRWAYS',
  ALTRA_AUTO_RENTAL: 'ALTRA_AUTO_RENTAL',
  AGENCY_RENT_A_CAR: 'AGENCY_RENT_A_CAR',
  BUDGET_RENT_A_CAR: 'BUDGET_RENT_A_CAR',
  HOLIDAY_RENT_A_CAR: 'HOLIDAY_RENT_A_CAR',
  RENT_A_WRECK: 'RENT_A_WRECK',
  ACCENT_RENT_A_CAR: 'ACCENT_RENT_A_CAR',
  AJAX_RENT_A_CAR: 'AJAX_RENT_A_CAR',
  TRIANGLE_RENT_A_CAR: 'TRIANGLE_RENT_A_CAR',
  EUROPCAR: 'EUROPCAR',
  TROPICAL_RENT_A_CAR: 'TROPICAL_RENT_A_CAR',
  SHOWCASE_RENTAL_CARS: 'SHOWCASE_RENTAL_CARS',
  ALAMO_RENT_A_CAR: 'ALAMO_RENT_A_CAR',
  MERCHANTS_RENT_A_CAR: 'MERCHANTS_RENT_A_CAR',
  AVIS_RENT_A_CAR: 'AVIS_RENT_A_CAR',
  DOLLAR_RENT_A_CAR: 'DOLLAR_RENT_A_CAR',
  EUROPE_BY_CAR: 'EUROPE_BY_CAR',
  NATIONAL_CAR_RENTAL: 'NATIONAL_CAR_RENTAL',
  KEMWELL_GROUP: 'KEMWELL_GROUP',
  THRIFTY_CAR_RENTAL: 'THRIFTY_CAR_RENTAL',
  TILDEN_RENT_A_CAR: 'TILDEN_RENT_A_CAR',
  ECONO_CAR_RENT_A_CAR: 'ECONO_CAR_RENT_A_CAR',
  AUTO_HOST_RENTAL_CARS: 'AUTO_HOST_RENTAL_CARS',
  ENTERPRISE_RENT_A_CAR: 'ENTERPRISE_RENT_A_CAR',
  GENERAL_RENT_A_CAR: 'GENERAL_RENT_A_CAR',
  A1_RENT_A_CAR: 'A1_RENT_A_CAR',
  GODFREY_NATIONAL: 'GODFREY_NATIONAL',
  ANSA_INTERNATIONAL: 'ANSA_INTERNATIONAL',
  ALLSTATE: 'ALLSTATE',
  AVCAR: 'AVCAR',
  AUTOMATE: 'AUTOMATE',
  AVON_RENT_A_CAR: 'AVON_RENT_A_CAR',
  CAREY: 'CAREY',
  INSURANCE_RENT_A_CAR: 'INSURANCE_RENT_A_CAR',
  MAJOR_RENT_A_CAR: 'MAJOR_RENT_A_CAR',
  REPLACEMENT_RENT_A_CAR: 'REPLACEMENT_RENT_A_CAR',
  RESERVE_RENT_A_CAR: 'RESERVE_RENT_A_CAR',
  UGLY_DUCKLING_RENT_A_CAR: 'UGLY_DUCKLING_RENT_A_CAR',
  USA_RENTAL: 'USA_RENTAL',
  VALUE_RENT_A_CAR: 'VALUE_RENT_A_CAR',
  AUTOHANSA: 'AUTOHANSA',
  CITE: 'CITE',
  INTERENT: 'INTERENT',
  MILLEVILLE: 'MILLEVILLE',
  ADVANTAGE_RENT_A_CAR: 'ADVANTAGE_RENT_A_CAR',
  HOLIDAY_INNS: 'HOLIDAY_INNS',
  BEST_WESTERN: 'BEST_WESTERN',
  SHERATON: 'SHERATON',
  HILTON: 'HILTON',
  TRUSTHOUSE_FORTE: 'TRUSTHOUSE_FORTE',
  GOLDEN_TULIP: 'GOLDEN_TULIP',
  FRIENDSHIP_INNS_INTERNATIONAL: 'FRIENDSHIP_INNS_INTERNATIONAL',
  QUALITY_INTERNATIONAL: 'QUALITY_INTERNATIONAL',
  MARRIOTT: 'MARRIOTT',
  DAYS_INNS_OF_AMERICA: 'DAYS_INNS_OF_AMERICA',
  ARABELLA_HOTELS: 'ARABELLA_HOTELS',
  INTERCONTINENTAL: 'INTERCONTINENTAL',
  WESTIN_HOTELS: 'WESTIN_HOTELS',
  AMERISUITES: 'AMERISUITES',
  RODEWAY_INNS_INTERNATIONAL: 'RODEWAY_INNS_INTERNATIONAL',
  LA_QUINTA_MOTOR_INNS: 'LA_QUINTA_MOTOR_INNS',
  AMERICANA_HOTELS_CORPORATION: 'AMERICANA_HOTELS_CORPORATION',
  SOL_HOTELS: 'SOL_HOTELS',
  PLM_ETAP_INTERNATIONAL: 'PLM_ETAP_INTERNATIONAL',
  MERIDIEN: 'MERIDIEN',
  CREST_HOTELS: 'CREST_HOTELS',
  TOKYO_GROUP: 'TOKYO_GROUP',
  PENINSULA_HOTELS: 'PENINSULA_HOTELS',
  WELCOMGROUP: 'WELCOMGROUP',
  DUNFEY_HOTELS: 'DUNFEY_HOTELS',
  PRINCE_HOTELS: 'PRINCE_HOTELS',
  DOWNTOWNER_PASSPORT: 'DOWNTOWNER_PASSPORT',
  THUNDERBIRD_RED_LION: 'THUNDERBIRD_RED_LION',
  CANADIAN_PACIFIC: 'CANADIAN_PACIFIC',
  RENAISSANCE_HOTELS: 'RENAISSANCE_HOTELS',
  KAUAI_COCONUT_BEACH_RESORT: 'KAUAI_COCONUT_BEACH_RESORT',
  ROYAL_KONA_RESORT: 'ROYAL_KONA_RESORT',
  PARK_INN_BY_RADISSON: 'PARK_INN_BY_RADISSON',
  SOUTHERN_PACIFIC_HOTEL: 'SOUTHERN_PACIFIC_HOTEL',
  HILTON_INTERNATIONAL: 'HILTON_INTERNATIONAL',
  AMFAC_HOTELS: 'AMFAC_HOTELS',
  ANA_HOTELS: 'ANA_HOTELS',
  CONCORDE_HOTELS: 'CONCORDE_HOTELS',
  SUMMERFIELD_SUITES_HOTEL: 'SUMMERFIELD_SUITES_HOTEL',
  IBEROTEL: 'IBEROTEL',
  HOTEL_OKURA: 'HOTEL_OKURA',
  ROYAL_HOTELS: 'ROYAL_HOTELS',
  FOUR_SEASONS: 'FOUR_SEASONS',
  CIGAHOTELS: 'CIGAHOTELS',
  SHANGRI_LA_INTERNATIONAL: 'SHANGRI_LA_INTERNATIONAL',
  HOTEL_SIERRA: 'HOTEL_SIERRA',
  BREAKERS_RESORT: 'BREAKERS_RESORT',
  HOTELS_MELIA: 'HOTELS_MELIA',
  AUBERGE_DES_GOVERNEURS: 'AUBERGE_DES_GOVERNEURS',
  REGAL_8_INNS: 'REGAL_8_INNS',
  MIRAGE_HOTEL_AND_CASINO: 'MIRAGE_HOTEL_AND_CASINO',
  COAST_HOTELS: 'COAST_HOTELS',
  PARKS_INNS_INTERNATIONAL: 'PARKS_INNS_INTERNATIONAL',
  PINEHURST_RESORT: 'PINEHURST_RESORT',
  TREASURE_ISLAND_HOTEL_AND_CASINO: 'TREASURE_ISLAND_HOTEL_AND_CASINO',
  BARTON_CREEK_RESORT: 'BARTON_CREEK_RESORT',
  MANHATTAN_EAST_SUITE_HOTELS: 'MANHATTAN_EAST_SUITE_HOTELS',
  JOLLY_HOTELS: 'JOLLY_HOTELS',
  CANDLEWOOD_SUITES: 'CANDLEWOOD_SUITES',
  ALADDIN_RESORT_AND_CASINO: 'ALADDIN_RESORT_AND_CASINO',
  GOLDEN_NUGGET: 'GOLDEN_NUGGET',
  COMFORT_HOTEL_INTERNATIONAL: 'COMFORT_HOTEL_INTERNATIONAL',
  JOURNEYS_END_MOTELS: 'JOURNEYS_END_MOTELS',
  SAMS_TOWN_HOTEL_AND_CASINO: 'SAMS_TOWN_HOTEL_AND_CASINO',
  RELAX_INNS: 'RELAX_INNS',
  GARDEN_PLACE_HOTEL: 'GARDEN_PLACE_HOTEL',
  SOHO_GRAND_HOTEL: 'SOHO_GRAND_HOTEL',
  LADBROKE_HOTELS: 'LADBROKE_HOTELS',
  TRIBECA_GRAND_HOTEL: 'TRIBECA_GRAND_HOTEL',
  GRAND_MET_FORUM_HOTELS: 'GRAND_MET_FORUM_HOTELS',
  GRAND_WAILEA_RESORT: 'GRAND_WAILEA_RESORT',
  MIYAKO_HOTEL_KINTETSU: 'MIYAKO_HOTEL_KINTETSU',
  SANDMAN_HOTELS: 'SANDMAN_HOTELS',
  VENTURE_INN: 'VENTURE_INN',
  VAGABOND_HOTELS: 'VAGABOND_HOTELS',
  LA_QUINTA_RESORT: 'LA_QUINTA_RESORT',
  MANDARIN_INTERNATIONAL: 'MANDARIN_INTERNATIONAL',
  FRANKENMUTH_BAVARIAN: 'FRANKENMUTH_BAVARIAN',
  HOTEL_MERCURE: 'HOTEL_MERCURE',
  HOTEL_DEL_CORONADO: 'HOTEL_DEL_CORONADO',
  DELTA_HOTELS: 'DELTA_HOTELS',
  CALIFORNIA_HOTEL_AND_CASINO: 'CALIFORNIA_HOTEL_AND_CASINO',
  RADISSON_BLU: 'RADISSON_BLU',
  PRINCESS_HOTELS_INTERNATIONAL: 'PRINCESS_HOTELS_INTERNATIONAL',
  HUNGAR_HOTELS: 'HUNGAR_HOTELS',
  SOKOS_HOTEL: 'SOKOS_HOTEL',
  DORAL_HOTELS: 'DORAL_HOTELS',
  HELMSLEY_HOTELS: 'HELMSLEY_HOTELS',
  DORAL_GOLF_RESORT: 'DORAL_GOLF_RESORT',
  FAIRMONT_HOTELS_CORPORATION: 'FAIRMONT_HOTELS_CORPORATION',
  SONESTA_INTERNATIONAL_HOTELS: 'SONESTA_INTERNATIONAL_HOTELS',
  OMNI_INTERNATIONAL: 'OMNI_INTERNATIONAL',
  CUNARD_HOTELS: 'CUNARD_HOTELS',
  ARIZONA_BILTMORE: 'ARIZONA_BILTMORE',
  HOSPITALITY_INNS: 'HOSPITALITY_INNS',
  WYNN_LAS_VEGAS: 'WYNN_LAS_VEGAS',
  RIVERSIDE_RESORT_AND_CASINO: 'RIVERSIDE_RESORT_AND_CASINO',
  REGENT_HOTELS: 'REGENT_HOTELS',
  PANNONIA_HOTELS: 'PANNONIA_HOTELS',
  SADDLEBROOK_RESORT_TAMPA: 'SADDLEBROOK_RESORT_TAMPA',
  TRADEWINDS_RESORT: 'TRADEWINDS_RESORT',
  HUDSON_HOTEL: 'HUDSON_HOTEL',
  NOAHS_HOTEL_MELBOURNE: 'NOAHS_HOTEL_MELBOURNE',
  HILTON_GARDEN_INN: 'HILTON_GARDEN_INN',
  JURYS_DOYLE_HOTEL_GROUP: 'JURYS_DOYLE_HOTEL_GROUP',
  JEFFERSON_HOTEL: 'JEFFERSON_HOTEL',
  FONTAINEBLEAU_RESORT: 'FONTAINEBLEAU_RESORT',
  GAYLORD_OPRYLAND: 'GAYLORD_OPRYLAND',
  GAYLORD_PALMS: 'GAYLORD_PALMS',
  GAYLORD_TEXAN: 'GAYLORD_TEXAN',
  C_MON_INN: 'C_MON_INN',
  MOEVENPICK: 'MOEVENPICK',
  MICROTEL_INN_AND_SUITES: 'MICROTEL_INN_AND_SUITES',
  AMERICINN: 'AMERICINN',
  TRAVELODGE_MOTELS: 'TRAVELODGE_MOTELS',
  HERMITAGE_HOTELS: 'HERMITAGE_HOTELS',
  AMERICAS_BEST_VALUE_INN: 'AMERICAS_BEST_VALUE_INN',
  GREAT_WOLF: 'GREAT_WOLF',
  ALOFT: 'ALOFT',
  BINIONS_HORSESHOE_CLUB: 'BINIONS_HORSESHOE_CLUB',
  EXTENDED_STAY: 'EXTENDED_STAY',
  MERLIN_HOTEL_PERTH: 'MERLIN_HOTEL_PERTH',
  DORINT_HOTELS: 'DORINT_HOTELS',
  LADY_LUCK_HOTEL_AND_CASINO: 'LADY_LUCK_HOTEL_AND_CASINO',
  HOTEL_UNIVERSAL: 'HOTEL_UNIVERSAL',
  STUDIO_PLUS: 'STUDIO_PLUS',
  EXTENDED_STAY_AMERICA: 'EXTENDED_STAY_AMERICA',
  EXCALIBUR_HOTEL_AND_CASINO: 'EXCALIBUR_HOTEL_AND_CASINO',
  DAN_HOTELS: 'DAN_HOTELS',
  EXTENDED_STAY_DELUXE: 'EXTENDED_STAY_DELUXE',
  SLEEP_INN: 'SLEEP_INN',
  THE_PHOENICIAN: 'THE_PHOENICIAN',
  RANK_HOTELS: 'RANK_HOTELS',
  SWISSOTEL: 'SWISSOTEL',
  RESO_HOTELS: 'RESO_HOTELS',
  SAROVA_HOTELS: 'SAROVA_HOTELS',
  RAMADA_INNS: 'RAMADA_INNS',
  HOWARD_JOHNSON: 'HOWARD_JOHNSON',
  MOUNT_CHARLOTTE_HOTELS: 'MOUNT_CHARLOTTE_HOTELS',
  HYATT_HOTELS_INTERNATIONAL: 'HYATT_HOTELS_INTERNATIONAL',
  SOFITEL_HOTELS: 'SOFITEL_HOTELS',
  NOVOTEL_SIEH_ACCOR: 'NOVOTEL_SIEH_ACCOR',
  STEIGENBERGER: 'STEIGENBERGER',
  ECONO_TRAVEL_MOTOR_HOTEL: 'ECONO_TRAVEL_MOTOR_HOTEL',
  QUEENS_MOAT_HOUSES: 'QUEENS_MOAT_HOUSES',
  SWALLOW_HOTELS: 'SWALLOW_HOTELS',
  GRUPO_HOTELS_HUSA_SA: 'GRUPO_HOTELS_HUSA_SA',
  DE_VERE_HOTELS: 'DE_VERE_HOTELS',
  RADISSON: 'RADISSON',
  RED_ROOF_INNS: 'RED_ROOF_INNS',
  IMPERIAL_LONDON_HOTELS: 'IMPERIAL_LONDON_HOTELS',
  EMBASSY_HOTELS: 'EMBASSY_HOTELS',
  PENTA_HOTELS: 'PENTA_HOTELS',
  LOEWS_HOTELS: 'LOEWS_HOTELS',
  SCANDIC_HOTELS: 'SCANDIC_HOTELS',
  SARA_HOTELS: 'SARA_HOTELS',
  OBEROI_HOTELS: 'OBEROI_HOTELS',
  NEW_OTANI_HOTELS: 'NEW_OTANI_HOTELS',
  TAJ_HOTELS_INTL: 'TAJ_HOTELS_INTL',
  KNIGHTS_INN: 'KNIGHTS_INN',
  METROPOLE_HOTELS: 'METROPOLE_HOTELS',
  CIRCUS_CIRCUS_HOTEL_AND_CASINO: 'CIRCUS_CIRCUS_HOTEL_AND_CASINO',
  HOTELES_EL_PRESIDENTE: 'HOTELES_EL_PRESIDENTE',
  FLAG_INNS: 'FLAG_INNS',
  HAMPTON_INNS: 'HAMPTON_INNS',
  STAKIS_HOTELS: 'STAKIS_HOTELS',
  LUXOR_HOTEL_AND_CASINO: 'LUXOR_HOTEL_AND_CASINO',
  MARITIM: 'MARITIM',
  ELDORADO_HOTEL_AND_CASINO: 'ELDORADO_HOTEL_AND_CASINO',
  ARCADE: 'ARCADE',
  ARCTIA: 'ARCTIA',
  CAMPANILE: 'CAMPANILE',
  IBUSZ_HOTELS: 'IBUSZ_HOTELS',
  RANTASIPI_HOTELS: 'RANTASIPI_HOTELS',
  INTERHOTEL_CEDOK: 'INTERHOTEL_CEDOK',
  MONTE_CARLO_HOTEL_AND_CASINO: 'MONTE_CARLO_HOTEL_AND_CASINO',
  CLIMAT_DE_FRANCE: 'CLIMAT_DE_FRANCE',
  CUMULUS_HOTELS: 'CUMULUS_HOTELS',
  SILVER_LEGACY_HOTEL_AND_CASINO: 'SILVER_LEGACY_HOTEL_AND_CASINO',
  HOTEIS_OTHAN: 'HOTEIS_OTHAN',
  ADAMS_MARK: 'ADAMS_MARK',
  SAHARA_HOTEL_AND_CASINO: 'SAHARA_HOTEL_AND_CASINO',
  BRADBURY_SUITES: 'BRADBURY_SUITES',
  BUDGET_HOST_INNS: 'BUDGET_HOST_INNS',
  BUDGETEL: 'BUDGETEL',
  SUISSE_CHALET: 'SUISSE_CHALET',
  CLARION_HOTEL: 'CLARION_HOTEL',
  COMPRI_HOTEL: 'COMPRI_HOTEL',
  CONSORT: 'CONSORT',
  COURTYARD_BY_MARRIOTT: 'COURTYARD_BY_MARRIOTT',
  DILLON_INN: 'DILLON_INN',
  DOUBLETREE: 'DOUBLETREE',
  DRURY_INN: 'DRURY_INN',
  ECONOMY_INNS_OF_AMERICA: 'ECONOMY_INNS_OF_AMERICA',
  EMBASSY_SUITES: 'EMBASSY_SUITES',
  EXCEL_INN: 'EXCEL_INN',
  FAIRFIELD_HOTEL: 'FAIRFIELD_HOTEL',
  HARLEY_HOTEL: 'HARLEY_HOTEL',
  MIDWAY_MOTOR_LODGE: 'MIDWAY_MOTOR_LODGE',
  MOTEL_6: 'MOTEL_6',
  LA_MANSION_DEL_RIO: 'LA_MANSION_DEL_RIO',
  REGISTRY_HOTELS: 'REGISTRY_HOTELS',
  RESIDENCE_INN: 'RESIDENCE_INN',
  ROYCE_HOTEL: 'ROYCE_HOTEL',
  SANDMAN_INN: 'SANDMAN_INN',
  SHILO_INN: 'SHILO_INN',
  SHONEYS_INN: 'SHONEYS_INN',
  VIRGIN_RIVER_HOTEL_AND_CASINO: 'VIRGIN_RIVER_HOTEL_AND_CASINO',
  SUPER_8_MOTEL: 'SUPER_8_MOTEL',
  RITZ_CARLTON: 'RITZ_CARLTON',
  FLAG_INNS_AUSTRALIA: 'FLAG_INNS_AUSTRALIA',
  BUFFALO_BILLS_HOTEL_AND_CASINO: 'BUFFALO_BILLS_HOTEL_AND_CASINO',
  QUALITY_PACIFIC_HOTEL: 'QUALITY_PACIFIC_HOTEL',
  FOUR_SEASONS_AUSTRALIA: 'FOUR_SEASONS_AUSTRALIA',
  FAIRFIELD_INN: 'FAIRFIELD_INN',
  CARLTON_HOTELS: 'CARLTON_HOTELS',
  CITY_LODGE_HOTELS: 'CITY_LODGE_HOTELS',
  KAROS_HOTELS: 'KAROS_HOTELS',
  PROTEA_HOTELS: 'PROTEA_HOTELS',
  SOUTHERN_SUN_HOTELS: 'SOUTHERN_SUN_HOTELS',
  CONRAD_HOTELS: 'CONRAD_HOTELS',
  WYNDHAM: 'WYNDHAM',
  RICA_HOTELS: 'RICA_HOTELS',
  INTER_NOR_HOTELS: 'INTER_NOR_HOTELS',
  SEA_PINES_RESORT: 'SEA_PINES_RESORT',
  RIO_SUITES: 'RIO_SUITES',
  BROADMOOR_HOTEL: 'BROADMOOR_HOTEL',
  BALLYS_HOTEL_AND_CASINO: 'BALLYS_HOTEL_AND_CASINO',
  JOHN_ASCUAGAS_NUGGET: 'JOHN_ASCUAGAS_NUGGET',
  MGM_GRAND_HOTEL: 'MGM_GRAND_HOTEL',
  HARRAHS_HOTELS_AND_CASINOS: 'HARRAHS_HOTELS_AND_CASINOS',
  OPRYLAND_HOTEL: 'OPRYLAND_HOTEL',
  BOCA_RATON_RESORT: 'BOCA_RATON_RESORT',
  HARVEY_BRISTOL_HOTELS: 'HARVEY_BRISTOL_HOTELS',
  COLORADO_BELLE_EDGEWATER_RESORT: 'COLORADO_BELLE_EDGEWATER_RESORT',
  RIVIERA_HOTEL_AND_CASINO: 'RIVIERA_HOTEL_AND_CASINO',
  TROPICANA_RESORT_AND_CASINO: 'TROPICANA_RESORT_AND_CASINO',
  WOODSIDE_HOTELS_AND_RESORTS: 'WOODSIDE_HOTELS_AND_RESORTS',
  TOWNEPLACE_SUITES: 'TOWNEPLACE_SUITES',
  MILLENNIUM_BROADWAY_HOTEL: 'MILLENNIUM_BROADWAY_HOTEL',
  CLUB_MED: 'CLUB_MED',
  BILTMORE_HOTEL_AND_SUITES: 'BILTMORE_HOTEL_AND_SUITES',
  CAREFREE_RESORTS: 'CAREFREE_RESORTS',
  ST_REGIS_HOTEL: 'ST_REGIS_HOTEL',
  THE_ELIOT_HOTEL: 'THE_ELIOT_HOTEL',
  CLUB_CORPORATION_CLUB_RESORTS: 'CLUB_CORPORATION_CLUB_RESORTS',
  WELLESLEY_INNS: 'WELLESLEY_INNS',
  BEVERLY_HILLS_HOTEL: 'BEVERLY_HILLS_HOTEL',
  CROWNE_PLAZA_HOTELS: 'CROWNE_PLAZA_HOTELS',
  HOMEWOOD_SUITES: 'HOMEWOOD_SUITES',
  PEABODY_HOTELS: 'PEABODY_HOTELS',
  GREENBRIAR_RESORTS: 'GREENBRIAR_RESORTS',
  AMELIA_ISLAND_PLANTATION: 'AMELIA_ISLAND_PLANTATION',
  HOMESTEAD: 'HOMESTEAD',
  CANYON_RANCH: 'CANYON_RANCH',
  KAHALA_MANDARIN_ORIENTAL_HOTEL: 'KAHALA_MANDARIN_ORIENTAL_HOTEL',
  ORCHID_AT_MAUNA_LAI: 'ORCHID_AT_MAUNA_LAI',
  HALEKULANI_HOTEL_WAIKIKI_PARC: 'HALEKULANI_HOTEL_WAIKIKI_PARC',
  PRIMADONNA_HOTEL_AND_CASINO: 'PRIMADONNA_HOTEL_AND_CASINO',
  WHISKEY_PETES_HOTEL_AND_CASINO: 'WHISKEY_PETES_HOTEL_AND_CASINO',
  CHATEAU_ELAN_WINERY_AND_RESORT: 'CHATEAU_ELAN_WINERY_AND_RESORT',
  BEAU_RIVAGE_HOTEL_AND_CASINO: 'BEAU_RIVAGE_HOTEL_AND_CASINO',
  BELLAGIO: 'BELLAGIO',
  FREMONT_HOTEL_AND_CASINO: 'FREMONT_HOTEL_AND_CASINO',
  MAIN_STREET_STATION_HOTEL_AND_CASINO: 'MAIN_STREET_STATION_HOTEL_AND_CASINO',
  SILVER_STAR_HOTEL_AND_CASINO: 'SILVER_STAR_HOTEL_AND_CASINO',
  STRATOSPHERE_HOTEL_AND_CASINO: 'STRATOSPHERE_HOTEL_AND_CASINO',
  SPRINGHILL_SUITES: 'SPRINGHILL_SUITES',
  CAESARS_HOTEL_AND_CASINO: 'CAESARS_HOTEL_AND_CASINO',
  NEMACOLIN_WOODLANDS: 'NEMACOLIN_WOODLANDS',
  VENETIAN_RESORT_HOTEL_AND_CASINO: 'VENETIAN_RESORT_HOTEL_AND_CASINO',
  NEW_YORK_NEW_YORK_HOTEL_AND_CASINO: 'NEW_YORK_NEW_YORK_HOTEL_AND_CASINO',
  SANDS_RESORT: 'SANDS_RESORT',
  NEVELE_GRANDE_RESORT_AND_COUNTRY_CLUB: 'NEVELE_GRANDE_RESORT_AND_COUNTRY_CLUB',
  MANDALAY_BAY_RESORT: 'MANDALAY_BAY_RESORT',
  FOUR_POINTS_HOTELS: 'FOUR_POINTS_HOTELS',
  W_HOTELS: 'W_HOTELS',
  DISNEYLAND_RESORTS: 'DISNEYLAND_RESORTS',
  PATRICIA_GRAND_RESORT_HOTELS: 'PATRICIA_GRAND_RESORT_HOTELS',
  ROSEN_HOTEL_AND_RESORTS: 'ROSEN_HOTEL_AND_RESORTS',
  TOWN_AND_COUNTRY_RESORT_AND_CONVENTION_CENTER: 'TOWN_AND_COUNTRY_RESORT_AND_CONVENTION_CENTER',
  FIRST_HOSPITALITY_HOTELS: 'FIRST_HOSPITALITY_HOTELS',
  OUTRIGGER_HOTELS_AND_RESORTS: 'OUTRIGGER_HOTELS_AND_RESORTS',
  OHANA_HOTELS_OF_HAWAII: 'OHANA_HOTELS_OF_HAWAII',
  CARIBE_ROYAL_RESORT_SUITE_AND_VILLAS: 'CARIBE_ROYAL_RESORT_SUITE_AND_VILLAS',
  ALA_MOANA_HOTEL: 'ALA_MOANA_HOTEL',
  SMUGGLERS_NOTCH_RESORT: 'SMUGGLERS_NOTCH_RESORT',
  RAFFLES_HOTELS: 'RAFFLES_HOTELS',
  STAYBRIDGE_SUITES: 'STAYBRIDGE_SUITES',
  CLARIDGE_CASINO_HOTEL: 'CLARIDGE_CASINO_HOTEL',
  THE_FLAMINGO_HOTELS: 'THE_FLAMINGO_HOTELS',
  GRAND_CASINO_HOTELS: 'GRAND_CASINO_HOTELS',
  PARIS_LAS_VEGAS_HOTEL: 'PARIS_LAS_VEGAS_HOTEL',
  PEPPERMILL_HOTEL_CASINO: 'PEPPERMILL_HOTEL_CASINO',
  ATLANTIC_CITY_HILTON: 'ATLANTIC_CITY_HILTON',
  EMBASSY_VACATION_RESORT: 'EMBASSY_VACATION_RESORT',
  HALE_KOA_HOTEL: 'HALE_KOA_HOTEL',
  HOMESTEAD_SUITES: 'HOMESTEAD_SUITES',
  WILDERNESS_HOTEL_AND_RESORT: 'WILDERNESS_HOTEL_AND_RESORT',
  THE_PALACE_HOTEL: 'THE_PALACE_HOTEL',
  THE_WIGWAM_GOLF_RESORT_AND_SPA: 'THE_WIGWAM_GOLF_RESORT_AND_SPA',
  THE_DIPLOMAT_COUNTRY_CLUB_AND_SPA: 'THE_DIPLOMAT_COUNTRY_CLUB_AND_SPA',
  THE_ATLANTIC: 'THE_ATLANTIC',
  PRINCEVILLE_RESORT: 'PRINCEVILLE_RESORT',
  ELEMENT: 'ELEMENT',
  LUXURY_RESORTS: 'LUXURY_RESORTS',
  SETTLE_INN: 'SETTLE_INN',
  LA_COSTA_RESORT: 'LA_COSTA_RESORT',
  PREMIER_TRAVEL_INNS: 'PREMIER_TRAVEL_INNS',
  HYATT_PLACE: 'HYATT_PLACE',
  HOTEL_INDIGO: 'HOTEL_INDIGO',
  THE_ROOSEVELT_HOTEL_NY: 'THE_ROOSEVELT_HOTEL_NY',
  NICKELODEON_FAMILY_SUITES_BY_HOLIDAY_INN: 'NICKELODEON_FAMILY_SUITES_BY_HOLIDAY_INN',
  HOME2SUITES: 'HOME2SUITES',
  AFFINIA: 'AFFINIA',
  MAINSTAY_SUITES: 'MAINSTAY_SUITES',
  OXFORD_SUITES: 'OXFORD_SUITES',
  JUMEIRAH_ESSEX_HOUSE: 'JUMEIRAH_ESSEX_HOUSE',
  CARIBE_ROYAL: 'CARIBE_ROYAL',
  CROSSLAND: 'CROSSLAND',
  GRAND_SIERRA_RESORT: 'GRAND_SIERRA_RESORT',
  ARIA: 'ARIA',
  VDARA: 'VDARA',
  AUTOGRAPH: 'AUTOGRAPH',
  GALT_HOUSE: 'GALT_HOUSE',
  COSMOPOLITAN_OF_LAS_VEGAS: 'COSMOPOLITAN_OF_LAS_VEGAS',
  COUNTRY_INN_BY_CARLSON: 'COUNTRY_INN_BY_CARLSON',
  PARK_PLAZA_HOTEL: 'PARK_PLAZA_HOTEL',
  WALDORF: 'WALDORF',
  HAINAN_AIRLINES: 'HAINAN_AIRLINES',
  WIZZ_AIR: 'WIZZ_AIR',
  AZUL_BRAZILIAN_AIRLINES: 'AZUL_BRAZILIAN_AIRLINES',
  FLYBE: 'FLYBE',
  TIGERAIR: 'TIGERAIR',
  EMIRATES: 'EMIRATES',
  CHINA_SOUTHERN_AIRLINES: 'CHINA_SOUTHERN_AIRLINES',
  GUNS_AMMO: 'GUNS_AMMO',
  PERSONAL_CREDIT_INSTITUTIONS: 'PERSONAL_CREDIT_INSTITUTIONS',
  PINLESS_DEBIT_BILLPAY_TIER_2: 'PINLESS_DEBIT_BILLPAY_TIER_2',
  INSURANCE_PREMIUMS: 'INSURANCE_PREMIUMS',
} as const;
