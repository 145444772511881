import { createI18n } from 'vue-i18n';
import messages from './messages';

const i18n = createI18n({
  // default locale
  locale: 'en',
  fallbackLocale: 'en',
  // translations
  messages,
});
export default i18n;
